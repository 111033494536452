import { create } from 'zustand';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const useEmployeeStore = create((set) => ({
  employees: [],
  setEmployees: (employees) => set({ employees }),
  fetchEmployees: async (storeID) => {
    const db = getFirestore();
    const q = query(
      collection(db, "users"),
      where("storeID", "==", storeID),
      where("typeOfUser", "==", "store"),
      where("storeOwner", "==", false),
      where("deactivated", "==", false),
    );
    const querySnapshot = await getDocs(q);
    const employees = querySnapshot.docs.map(doc => doc.data());
    set({ employees });
  },
}));

export default useEmployeeStore;