import { Fragment, useState } from 'react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import Success from './SuccessNotification';
import useStoreStore from './store/storeStore';
import useEmployeeStore from './store/employeesStore';

export default function AddUser({ onAdd }) {
  const [open, setOpen] = useState(false);
  const store = useStoreStore(state => state.store);
  const { fetchEmployees } = useEmployeeStore();

  const handleClick = () => {
    setOpen(true);
  };

  async function createNewUser(email, password, firstName, lastName, storeID) {
    // Initialize Firebase Auth and Firestore instances
    const auth = getAuth();
    const firestore = getFirestore();
  
    try {
      // Create a new user with the provided email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;
  
      // Create a new document in the 'users' collection with the user's UID as the document name
      await setDoc(doc(firestore, 'users', user.uid), {
        firstName,
        lastName,
        email,
        storeID,
        typeOfUser: 'store',
        storeOwner: false,
        deactivated: false,
      });
  
      console.log('User created successfully');
    } catch (error) {
      console.error('Error creating new user:', error);
    }
  }
  
  

    return (
      <>
      {/* <Success show={} setShow={} body={}  /> */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
<form
  onSubmit={async (e) => {
    e.preventDefault();
    const firstName = e.target.elements['first-name'].value;
    const lastName = e.target.elements['last-name'].value;
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    await createNewUser(email, password, firstName, lastName, store.storeID);
    await fetchEmployees(store.storeID);
    onAdd();
  }}
>

      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <p className="mt-1 text-sm leading-6 text-gray-600">Add a new user to your account.</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light"
        >
          Add User
        </button>
      </div>
    </form>

    </div>
      </>
    )
  }