import { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import useStoreStore from './store/storeStore';

export default function EditStore({ currentReward }) {
  const [totalStamps, setTotalStamps] = useState(5);
  const [reward, setReward] = useState('');
  const [file, setFile] = useState(null);
  const [updatedStamps, setUpdatedStamps] = useState(false);
  const [updatedReward, setUpdatedReward] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(false);
  const store = useStoreStore(state => state.store);

  const updateTotalStamps = async (event) => {
    event.preventDefault();
    const db = getFirestore();
  
    // Get the store with the desired storeID
    const q = query(collection(db, 'stores'), where('storeID', '==', store.storeID));
    const querySnapshot = await getDocs(q);
  
    // If a store with the desired storeID is found, update the totalStamps property
    if (!querySnapshot.empty) {
      const storeDoc = querySnapshot.docs[0];
      await updateDoc(storeDoc.ref, { totalStamps });
      setUpdatedStamps(true);
    }
  }

  const updateReward = async (event) => {
    event.preventDefault();
  
    // Check if the callToAction field is not empty
    if (reward.trim() !== '') {
      const db = getFirestore();
  
      // Get the store with the desired storeID
      const q = query(collection(db, 'stores'), where('storeID', '==', store.storeID));
      const querySnapshot = await getDocs(q);
  
      // If a store with the desired storeID is found, update the callToAction property
      if (!querySnapshot.empty) {
        const storeDoc = querySnapshot.docs[0];
        await updateDoc(storeDoc.ref, { reward });
        setUpdatedReward(true);
      } else {
        alert('No store found with the given storeID.');
      }
    } else {
      alert('Please enter a valid call to action.');
    }
  };

  const uploadLogo = async (event) => {
    event.preventDefault();

    const storage = getStorage();
    const logoRef = ref(storage, `logos/${store.storeID}`);
    await uploadBytes(logoRef, file);

    const logoUrl = await getDownloadURL(logoRef);

    const db = getFirestore();
    const storeRef = doc(db, 'stores', store.storeID);
    await updateDoc(storeRef, { logoUrl });

    setUploadedLogo(true);
  }

  return <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

<form onSubmit={updateTotalStamps}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
  
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
  
            <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                  Number of Stamps
                </label>
                <div className="mt-2">
                  <select
                     id="totalStamps"
                     name="totalStamps"
                     autoComplete="total-stamps"
                     value={totalStamps}
                     onChange={(e) => setTotalStamps(parseInt(e.target.value))}
                     className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                   >
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light"
          >
            Update
          </button>
          {updatedStamps && (
          <p className="text-sm text-green-600">Your store was updated.</p>
        )}
        </div>
      </form>
  
      <form onSubmit={updateReward}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
  
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                Reward
              </label>
              <div className="mt-2">
              <textarea
  id="about"
  name="about"
  rows={3}
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
  value={reward}
  onChange={(e) => setReward(e.target.value)}
  placeholder={currentReward}
/>
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-600">Update current reward.</p>
            </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light"
          >
            Update
          </button>
          {updatedReward && (
          <p className="text-sm text-green-600">Your store was updated.</p>
        )}
        </div>
      </form>
      </div>
  }

