import { collection, doc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure to import your Firebase configuration
import useStoreStore from './store/storeStore';
import useEmployeeStore from './store/employeesStore';


export default function DeleteUser({ onDelete }) {

  const store = useStoreStore(state => state.store);
  const { fetchEmployees } = useEmployeeStore();

    async function deactivateUserByEmail(email) {
        const usersRef = collection(db, 'users');
        const usersQuery = query(usersRef, where('email', '==', email));
        const usersSnapshot = await getDocs(usersQuery);
    
        if (!usersSnapshot.empty) {
          const userDoc = usersSnapshot.docs[0];
          await updateDoc(doc(db, 'users', userDoc.id), { deactivated: true });
        } else {
          console.log('No matching user found');
        }
      }
      
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        const emailInput = e.target.elements.email.value;
        await deactivateUserByEmail(emailInput);
        onDelete()
        await fetchEmployees(store.storeID);
      }}
    >

      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <p className="mt-1 text-sm leading-6 text-gray-600">Please confirm the email of the user you want to delete.</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light"
        >
          Delete
        </button>
      </div>
    </form>
  )
}
