import React, { useState, useEffect, useContext } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import Slideover from './SlideOver'
import EditStore from './EditStore'
import useCurrentUserStore from './store/currentUserStore';
import useStoreStore from './store/storeStore';

export default function StoreHeader() {
  const [imageUrl, setImageUrl] = useState('');
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const currentUser = useCurrentUserStore(state => state.currentUser);
  const store = useStoreStore(state => state.store);

  useEffect(() => {
    if (store) {
      const fetchImage = async () => {
        const storage = getStorage();
        const imageRef = ref(storage, store.logoUrl);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      };

      fetchImage();
    }
  }, [store]);


return (
  <>
      <Slideover open={slideOverOpen} handleClose={() => setSlideOverOpen(false)} title={"Edit store details"}>
      <EditStore currentReward={store ? store.reward : ''} />
    </Slideover>
  <div className="h-40 overflow-hidden rounded-lg bg-white shadow">
        <div className="bg-whitet p-10">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <img className="mx-auto h-20 w-20 rounded-full" src={imageUrl} alt="" />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                <p className="text-xl font-bold text-brand-purple sm:text-3xl">{currentUser ? currentUser.firstName : "Loading..."}</p>
                <p className="text-sm font-medium text-gray-600">{store ? store.storeName : "Loading..."}</p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <a
                href="#"
                className="flex items-center justify-center rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light"
                onClick={() => setSlideOverOpen(true)}
              >
                Edit store
              </a>
            </div>
          </div>
        </div>
      </div>
      </>
)
  }
  