import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Failed from '../components/FailedNotification';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import useCurrentUserStore from '../components/store/currentUserStore';
import useStoreStore from '../components/store/storeStore';
import useStore from '../components/store/useStore';
import useEmployeeStore from '../components/store/employeesStore';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const setCurrentUser = useCurrentUserStore(state => state.setCurrentUser);
  const setStore = useStoreStore(state => state.setStore)
  const fetchEmployees = useEmployeeStore(state => state.fetchEmployees);
  const setAuth = useStore(state => state.setAuth);
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
  
      // Access the Firestore
      const db = getFirestore();
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists() && userDoc.data().storeOwner) {
        setCurrentUser(userDoc.data());

        const storeID = userDoc.data().storeID;
        const q = query(collection(db, "stores"), where("storeID", "==", storeID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setStore({ ...doc.data() });
        });
        await fetchEmployees(storeID);
        setAuth(true);
        navigate('/home');
      } else {
        throw new Error('User is not a store owner');
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      setAuthError(error.message); // Set the authError state
    }
  };

  return (
    <>
    <Failed show={authError !== ''} setShow={(value) => setAuthError('')} body={'Failed to login, please check your credentials.'} />
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-60 w-auto"
          src="/logo_big.png"
          alt="Your Company"
        />
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your Penguin Dashboard
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
      onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="text-sm">
                <a href="https://t.me/penguinloyalty
" className="font-semibold text-brand-red hover:text-brand-red-light">
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
      onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-brand-red px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-red-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <a href="https://penguinloyalty.com" className="font-semibold leading-6 text-brand-red hover:text-brand-red-light">
            Signup to Penguin
          </a>
        </p>
      </div>
    </div>
    </>
  );
}

export default Login;