import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default function ChangePassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const auth = getAuth();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await sendPasswordResetEmail(auth, email);
        setMessage('Password reset email has been sent.');
      } catch (error) {
        setMessage('Error: ' + error.message);
      }
    };
  return (
   <>
   <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <p className="mt-1 text-sm leading-6 text-gray-600">Please confirm the email from the account that you want to change password. You'll recieve an email with the instructions to change password</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
          required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
     {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}</>
  )
}
