import React, { useState, useEffect } from 'react';
import useEmployeeStore from './store/employeesStore';
import useCurrentUserStore from './store/currentUserStore';
import Slideover from './SlideOver';
import AddUser from './AddUser';
import ChangePassword from './ChangePassword';
import DeleteUser from './DeleteUser';

export default function UserTable() {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [newUserSlideOverOpen, setNewUserSlideOverOpen] = useState(false);
  const [changePasswordSlideOverOpen, setPasswordSlideOverOpen] = useState(false);
  const [deleteUserSlideOverOpen, setDeleteSlideOverOpen] = useState(false);
  const employees = useEmployeeStore(state => state.employees);
  const currentUser = useCurrentUserStore(state => state.currentUser);
  const [isAddUserDisabled, setIsAddUserDisabled] = useState(false);
  
  const handleDelete = () => {
    setDeleteSlideOverOpen(false)
  }

  const handleAddUser = () => {
    setNewUserSlideOverOpen(false)
  }

  useEffect(() => {
    if (currentUser && employees.length >= currentUser.accountsAllowed) {
      setIsAddUserDisabled(true);
      
    } else {
      setIsAddUserDisabled(false);
      console.log("habilitado");
    }
  }, [employees, currentUser]);

    return (
      <>
      <Slideover open={newUserSlideOverOpen} handleClose={() => setNewUserSlideOverOpen(false)} title={"Add New User"}>
      <AddUser onAdd={handleAddUser} />
    </Slideover>

    <Slideover open={changePasswordSlideOverOpen} handleClose={() => setPasswordSlideOverOpen(false)} title={'Change Password'}>
      <ChangePassword />
    </Slideover>

    <Slideover open={deleteUserSlideOverOpen} handleClose={() => setDeleteSlideOverOpen(false)} title={'Delete User'}>
      <DeleteUser onDelete={handleDelete} />
    </Slideover>
      <div className="mt-10 px-4 sm:px-6 lg:px-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base text-left font-semibold leading-6 text-gray-900">Users</h1>
            <p className="text-left mt-2 text-sm text-gray-700">
              A list of all the users in your account.
            </p>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
            <button
            onClick={() => setNewUserSlideOverOpen(true)}
              type="button"
              disabled={isAddUserDisabled}
              className={`w-30 block rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm 
    ${isAddUserDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-brand-red text-white hover:bg-brand-red-light'}`}
            >
              Add user
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      User
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
  
                  {employees.map((employees, index) => (
                    <tr key={employees.email}>
                      <td className="text-left whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {employees.firstName}
                      </td>
                      <td className="text-left whitespace-nowrap px-3 py-4 text-sm text-gray-500">{employees.email}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                        onClick={() => {setPasswordSlideOverOpen(true)}}
                          className="text-brand-red-light hover:text-brand-red-light focus:outline-none"
                          >
                          Change Password<span className="sr-only">, {employees.firstName}</span>
                        </button>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                        onClick={() => {setDeleteSlideOverOpen(true)}}
                          className="text-brand-red hover:text-brand-red-light focus:outline-none"
                          >
                          Delete<span className="sr-only">, {employees.firstName}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  