import StoreStats from "../components/StoreStats";
import UserTable from "../components/UserTable";
import StoreHeader from "./StoreHeader";

const storeStats = [
  { name: 'Active Users', stat: '37', previousStat: '30', change: '23.3%', changeType: 'increase' },
  { name: 'Card Redemption Rate', stat: '8.16%', previousStat: '6.14%', change: '32.8%', changeType: 'increase' },
  { name: 'Avg. Stamps per Day', stat: '8', previousStat: '12', change: '33%', changeType: 'decrease' },
]

const employees = [
  { firstName: 'Clara', email: 'clara.holden@gmail.com', role: 'Manager' },
]

function DashboardView() {

  return (
    <>
      {/* <StoreStats stats={storeStats} /> */}
      <UserTable employees={employees} />
    </>
  );
} 

export default DashboardView;