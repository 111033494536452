// store.js
import { create } from 'zustand'

const useStore = create(set => ({
  currentView: 'Dashboard',
  isAuthenticated: false,
  setView: view => set({ currentView: view }),
  setAuth: authStatus => set({ isAuthenticated: authStatus }),
}))

export default useStore