import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../components/store/useStore';
import Layout from "../components/Layout";


function Home() {
  const navigate = useNavigate();
  const isAuthenticated = useStore(state => state.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);
  return (
    <Layout>
    </Layout>
  );
}

export default Home;